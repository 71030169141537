import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import intl from 'react-intl-universal'
import { pick } from 'ramda'
import { Mutation } from '@apollo/client/react/components'
import { Popover } from 'react-tiny-popover'
import { Transition } from '@headlessui/react'
import Item from 'components/navbar/chapter-list/Item'
import { BASE_URL } from 'constants/General'
import { createChapterMutation } from 'graphql/mutations/chapter/createChapter'
import { updateChapterMutation } from 'graphql/mutations/chapter/updateChapter'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { showModal, showModal2 } from 'components/utils/CustomModal'
import { copyToClipboard, getDaysFromEpoch2, calculateEngagement, camelCase, isColorDark } from 'utils/functions'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { Course } from 'graphql/schemas/course/Course'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { AppState } from 'graphql/schemas/app/State'
import { duplicateChapterMutation } from 'graphql/mutations/chapter/duplicateChapter'
import { UserEngagement } from 'graphql/schemas/user/UserEngagement'
import { client, updateAppCache } from 'app'
import { augmentName } from 'utils/utils'
import { User } from 'graphql/schemas/user/User'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { isActionCompleted } from 'models/action_models'
import { Button } from 'components/utils/Button'
import { PptUploader2, QuestionsUploader } from 'components/editor/course/Sidebar'
import { track } from 'utils/track'
import { withRouter } from 'components/utils/withRouterWrapper'
import { Location, NavigateFunction } from 'react-router-dom'
import { history } from 'utils/history'
import { useWhiteLabel } from 'context/whiteLabel'
import MagicCreator from 'components/MagicCreator'
import MagicStarsIcon from 'components/icons/MagicStars'

const today = getDaysFromEpoch2(new Date())

interface SortableItemProps {
  user: Pick<User, 'id'|'profile'>;
  chaptersUnlocked: string;
  ch: Pick<Chapter, 'id'|'courseId'|'disabledUntilDay'|'timestamps'|'order'|'assessmentMode'|'title'>;
  companyId: string;
  progress: Pick<UserProgress, 'currentDay'|'startedAt'|'review'|'reviewTimestamps'>|null;
  chapterEngagement: Pick<UserEngagement, 'timestamps'|'reviewTimestamps'|'engagement'>|null;
  activeChapterId: string;
  engagement: number;
  isEditing: boolean;
  actionsLeft: number;
  hasCertificate: boolean;
  onClick: () => void;
}

const SortableItem = (props: SortableItemProps) => {
  const progress = props.progress
  const unlocked = (!props.ch.disabledUntilDay || props.ch.disabledUntilDay <= today) && (
    (props.chaptersUnlocked === 'unlocked') ||
    (props.chaptersUnlocked === 'daily' && progress && (progress.currentDay || 0) >= props.ch.order) ||
    (props.chaptersUnlocked === 'streak' && progress && (progress.currentDay || 0) >= props.ch.order)
  )
  const chapter = props.ch
  const engagement = props.chapterEngagement
  const isChapterLocked = (chapter.disabledUntilDay && !engagement) || (progress && (chapter.order > (progress.currentDay || 0))) || (chapter.disabledUntilDay && chapter.disabledUntilDay > today)
  const ps = progress && progress.startedAt || 0
  let chapterStatus = chapter.timestamps.createdAt > Math.max(engagement && engagement.timestamps.updatedAt || 0, ps)
    ? intl.get('new_tag')
    : chapter.timestamps.updatedAt > Math.max(engagement && engagement.timestamps.updatedAt || 0, ps)
    || chapter.timestamps.createdAtTree > Math.max(engagement && engagement.timestamps.updatedAtTree || 0, ps)
    || chapter.timestamps.updatedAtTree > Math.max(engagement && engagement.timestamps.updatedAtTree || 0, ps)
      ? intl.get('updated_tag')
      : ''

  const isSubmitted: boolean = !!(progress?.review && progress.review.status !== 'notSet')
  const hasNewReviews = !engagement?.reviewTimestamps || (engagement.reviewTimestamps.createdAt > (progress?.reviewTimestamps?.updatedAt || 0))
  const hasNewReviewsTree = (engagement?.reviewTimestamps?.createdAtTree || 0) > (progress?.reviewTimestamps?.updatedAtTree || 0)
  const unseenReviews: boolean = isSubmitted && (hasNewReviews || hasNewReviewsTree)

  if (unseenReviews) {
    chapterStatus = intl.get('feedback_tag')
  }

  if (isChapterLocked) {
    chapterStatus = ''
  }

  const isEditing = props.isEditing
  const companyId = props.companyId || ''
  const engagementino = (!chapter.assessmentMode || (chapter.assessmentMode && props.chapterEngagement && props.chapterEngagement.engagement !== 0)) ? props.engagement : 0
  const isActive = chapter.id === props.activeChapterId
  const isCompleted = !isChapterLocked && props.engagement === 100 && (!chapter.assessmentMode || chapter.assessmentMode && props.chapterEngagement && props.chapterEngagement.engagement === 100)
  const isEnabled = props.ch.order === 1 || unlocked || false
  const currentDay = progress?.currentDay || 1

  const title = augmentName(chapter.title)(props.user)

  return (
    <Item
      key={chapter.id}
      companyId={companyId}
      active={isActive}
      completed={!!isCompleted}
      engagement={engagementino}
      enabled={isEnabled}

      id={chapter.id}
      title={title}
      disabledUntilDay={chapter.disabledUntilDay}
      order={chapter.order}
      courseId={chapter.courseId}
      actionsLeft={props.actionsLeft}
      streak={props.chaptersUnlocked === 'streak'}

      currentDay={currentDay}
      unlocked={isEnabled}
      isEditing={isEditing}
      tag={chapterStatus}
      hasCertificate={props.hasCertificate}
      onClick={props.onClick}
    />
  )
}

interface SortableListProps {
  chaptersUnlocked: string;
  subscription: UserSubscription;
  chapters: Chapter[];
  study: UserStudy[];
  engagement: UserEngagement[];
  user: User;
  activeChapterId: string;
  isEditing: boolean;
  onClick: (id: string) => void;
}

const SortableList = (props: SortableListProps) => {
  const list = props.chapters && props.chapters.slice().sort((a, b) => a.order - b.order) || []
  return (
    <ul className="chapter-list">
      {list.map((ch, i) => {
        const engagement = calculateEngagement(ch.actions, props.study)
        const chapterEng = props.engagement.find(s => s.chapterId === ch.id)
        const chapterEngagement = chapterEng && pick(['timestamps', 'reviewTimestamps', 'engagement'], chapterEng) || null
        const chapterProgress = props.subscription.progress && pick(['currentDay', 'startedAt', 'review', 'reviewTimestamps'], props.subscription.progress) || null
        const chapter = {
          id: ch.id,
          courseId: ch.courseId,
          disabledUntilDay: ch.disabledUntilDay,
          timestamps: ch.timestamps,
          order: ch.order,
          assessmentMode: ch.assessmentMode,
          title: ch.title,
        }
        const actionsLeft = ch.actions
          ? ch.actions.filter(a => !isActionCompleted(a.contentType, a.placeholderAnswer || '', props.study?.find(s => s.chapterId === a.chapterId && s.actionId === a.id) || null)).length
          : 0
        const onClick = () => props.onClick(ch.id)
        return (
          <SortableItem
            key={ch.id}
            ch={chapter}
            user={pick(['id', 'profile'], props.user)}
            engagement={engagement}
            activeChapterId={props.activeChapterId}
            chaptersUnlocked={props.chaptersUnlocked}
            progress={chapterProgress}
            companyId={props.subscription.companyId}
            chapterEngagement={chapterEngagement}
            actionsLeft={actionsLeft}
            hasCertificate={ch.actions && ch.actions.some(a => a.contentType === 'certificate') || false}
            isEditing={props.isEditing}
            onClick={onClick}
          />
        )
      })}
    </ul>
  )
}

interface ChapterListProps {
  user: User;
  course: Course;
  companyName: string;
  chapters: Chapter[];
  subscription: UserSubscription;
  app: AppState;
  study: UserStudy[];
  engagement: UserEngagement[];
  isEditor: boolean;
  visible: boolean;
  location: Location<any>;
  hideDrawer: () => void;
  navigate: NavigateFunction;
}

class ChapterList extends React.Component<ChapterListProps> {
  constructor(props) {
    super(props)
    this.handleChapterSelection = this.handleChapterSelection.bind(this)
  }

  componentDidUpdate(prevProps) {
    const chapterList = document.querySelector('.chapter-list-container')
    const activeChapter: HTMLElement|null = document.querySelector('.active-chapter')

    if (!chapterList || !activeChapter || this.props.visible) return

    chapterList.scrollTop = activeChapter.offsetTop - 50
  }

  handleChapterSelection(chapterId: string) {
    this.props.hideDrawer()
    this.props.navigate(`/learn/${chapterId}`)
  }

  clearClipboard() {
    localStorage.removeItem('copiedChapter')
  }

  render() {
    const { visible, course, chapters, subscription, user, study, engagement, app } = this.props

    if (!course || !chapters) {
      return <FullscreenLoader />
    }

    const testCompany = course.testCompany
    const accessCodes = testCompany && testCompany.accessCodes
    const lastChapter = chapters && chapters.slice().sort((a, b) => b.order - a.order)[0]

    const classes = classNames({
      visible,
      'chapter-list-container': true,
      'w-[24rem] max-w-[9/10]': true,
    })

    const shareUrl = `${BASE_URL}/d/joinGroup/${accessCodes && accessCodes[0] || ''}`

    return (
      <div className={classes}>
        {app.appState.isEditing && accessCodes &&
          <div className="bg-teak p-4 text-white -mt-4 -mx-4 mb-6">
            <strong>{intl.get('menu_invite_people')}</strong>
            <p className="mt-4 mb-0">{intl.getHTML('menu_share_program', { 0: '' })}</p>
            <p className="p-0 m-0">
              <a href={shareUrl} className="text-team" target="_blank" rel="noreferrer">{shareUrl.replace('https://', '')}</a>
              <span className="icon-link-light float-right cursor-pointer" style={{ marginTop: 2 }} onClick={() => copyToClipboard(shareUrl)} />
            </p>
          </div>
        }

        <h3
          className="cursor-pointer leading-6"
          onClick={(e) => {
            this.props.navigate(`/details/${course.id}`)
          }}>
            <div className="mb-1 text-sm text-team font-normal">{this.props.companyName}</div>
            {course.title}
        </h3>

        <Mutation
          mutation={updateChapterMutation}>
          {update => (
            <SortableList
              chaptersUnlocked={course.chaptersUnlocked}
              subscription={subscription}
              study={study}
              activeChapterId={app.appState.activeChapterId}
              isEditing={app.appState.isEditing}
              chapters={chapters}
              engagement={engagement}
              user={user}
              onClick={this.handleChapterSelection}
            />
          )}
        </Mutation>

        {app.appState.isEditing &&
          <CreateNewChapterButton
            courseId={this.props.course.id}
            order={chapters.length + 1}
            lastChapterDisabledUntilDay={lastChapter?.disabledUntilDay || today}
            canUploadPpt={user.features.includes('course-from_ppt')}
          />
        }
      </div>
    )
  }
}

export default withRouter(ChapterList)

export const createChapter = (courseId, order, lastChapterDisabledUntilDay) => {
  const today = getDaysFromEpoch2(new Date())
  const yday = today - 1
  const copiedChapter = localStorage.getItem('copiedChapter') || ''
  const create = (title, order, disabledUntilDay?) => client.mutate({
    mutation: createChapterMutation,
    variables: {
      courseId,
      chapterCreate: {
        order,
        title: title || intl.get('new_chapter'),
        disabledUntilDay: disabledUntilDay || yday,
      },
    },
    update: (proxy, { data: { createChapter } }) => {
      history.navigate && history.navigate(`/learn/${createChapter.id}`)
      updateAppCache('activeChapterId', createChapter.id)

      track({
        event: 'Session Created',
        variables: {
          programId: courseId,
        },
      })
    },
  })
  if (copiedChapter) {
    const chapter = JSON.parse(copiedChapter)
    const modal = showModal({
      title: 'Session available in the clipboard...',
      content: `<p>You have session <strong>${chapter.chapterTitle}</strong> in your clipboard.</p><p className="m-0 p-0">Would you like to paste it or create a new one?</p>`,
      primaryText: intl.get('global_paste'),
      primaryAction: () => {
        client.mutate({
          mutation: duplicateChapterMutation,
          variables: {
            ...chapter,
            toCourseId: courseId,
            chapterUpdate: {},
          },
          update: (proxy, { data: { duplicateChapter } }) => {
            updateAppCache('activeChapterId', duplicateChapter.id)
            track({
              event: 'Session Duplicated',
              variables: {
                programId: courseId,
                originalSessionId: chapter.chapterId,
              },
            })
          },
        })
      },
      secondaryText: intl.get('create_new_session'),
      secondaryAction: () => {
        modal.close()
        create(camelCase(intl.get('session_number', { 0: order })), order, lastChapterDisabledUntilDay || yday)
        // setTimeout(() => {
        //   showModal({
        //     title: intl.get('action_modal_set_title'),
        //     content: intl.get('chapter_modal_title_placeholder'),
        //     prompt: true,
        //     primaryAction: (r) => {
        //       create(r, order, lastChapterDisabledUntilDay || yday)
        //     },
        //   })
        // }, 300)
      },
      cancelButton: intl.get('global_clear_clipboard'),
      cancelAction: () => localStorage.removeItem('copiedChapter'),
    })
  }
  else {
    create(camelCase(intl.get('session_number', { 0: order })), order, lastChapterDisabledUntilDay || yday)
    // showModal({
    //   title: intl.get('action_modal_set_title'),
    //   content: intl.get('chapter_modal_title_placeholder'),
    //   prompt: true,
    //   primaryAction: (r) => {
    //     create(r, order, lastChapterDisabledUntilDay || yday)
    //   },
    // })
  }
}

export const CreateNewChapterButton = ({ courseId, order, lastChapterDisabledUntilDay, canUploadPpt, collapsed, disabled, magicSessionCreationEnabled, setShowPlaceholderChapter }: {courseId: string, order: any, lastChapterDisabledUntilDay: any, canUploadPpt: any, collapsed?: boolean, disabled?: boolean, magicSessionCreationEnabled?: boolean, setShowPlaceholderChapter?: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { whiteLabelData } = useWhiteLabel()
  const primaryColor = whiteLabelData.primaryColor
  const bgColor = whiteLabelData.bgColor

  const showMagic = () => {
    if (setShowPlaceholderChapter) {
      setShowPlaceholderChapter(true)
    }
    const closeModal = showModal2({
      onlyContent: true,
      className: 'post',
      scrollable: true,
      component: <MagicCreator typeOfCreator="chapter" courseId={courseId} closeModal={()=>closeModal.close()} />,
      onHideModal: () => setShowPlaceholderChapter && setShowPlaceholderChapter(false),
      disableClickOutside: true,
    })
  }

  return (
    <div className="flex gap-2">
      {collapsed ? <div>
        <button
          onClick={() => createChapter(courseId, order, lastChapterDisabledUntilDay)}
          className={`icon icon-plus-circle-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral ${disabled && 'cursor-not-allowed hover:text-lightgray'}`}
          disabled={disabled}
        />
        </div>
        : <>
          <Button
            onClick={() => createChapter(courseId, order, lastChapterDisabledUntilDay)}
            type="team"
            style={primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}}
            className={`flex-1 px-3 ${primaryColor && 'bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)]'} ${isColorDark(primaryColor) && 'text-zinc-50'} ${primaryColor === bgColor && 'shadow-md'}`}
            svgIcon={<svg className="w-5 h-5 inline-block mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 10.8333H10.8333V15.8333H9.16663V10.8333H4.16663V9.16663H9.16663V4.16663H10.8333V9.16663H15.8333V10.8333Z" fill={`${isColorDark(primaryColor) ? '#fafafa' : '#101010'}`}></path></svg>}
            text={intl.get('button_add_session')}
            id="button-add-session"
            disabled={disabled}
          />

          <Popover
            isOpen={isOpen}
            positions={['top', 'right']}
            align="start"
            padding={10}
            onClickOutside={() => setIsOpen(false)}
            content={() => (
              <Transition
                show={true}
                appear={true}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="w-58 custom-dropdown z-10 text-sm">
                  <PptUploader2
                    courseId={courseId}
                    chapterCount={order - 1}
                    isImportingPpt={false}
                    v2={true}
                    upsell={!canUploadPpt}
                    onClose={() => setIsOpen(false)}
                    disabled={disabled}
                    isMenuItem={true}
                  />
                  <QuestionsUploader
                    courseId={courseId}
                    onClose={() => setIsOpen(false)}
                  />
                </div>
              </Transition>
            )}>

            <button
              className="btn-v2 inline-flex items-center justify-center border-gray-300"
              data-test="import-pptx-csv-btn"
              onClick={() => setIsOpen(true)}>
                <svg className="inline-block" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.99992 13.6667C1.54159 13.6667 1.14909 13.5033 0.82242 13.1767C0.495753 12.85 0.332698 12.4578 0.333253 12V10.3333C0.333253 10.0972 0.413253 9.89917 0.573253 9.73917C0.733253 9.57917 0.931031 9.49944 1.16659 9.5C1.4027 9.5 1.60075 9.58 1.76075 9.74C1.92075 9.9 2.00048 10.0978 1.99992 10.3333V12H11.9999V10.3333C11.9999 10.0972 12.0799 9.89917 12.2399 9.73917C12.3999 9.57917 12.5977 9.49944 12.8333 9.5C13.0694 9.5 13.2674 9.58 13.4274 9.74C13.5874 9.9 13.6671 10.0978 13.6666 10.3333V12C13.6666 12.4583 13.5033 12.8508 13.1766 13.1775C12.8499 13.5042 12.4577 13.6672 11.9999 13.6667H1.99992ZM6.16659 3.54167L4.60409 5.10417C4.43742 5.27083 4.23936 5.35083 4.00992 5.34417C3.78048 5.3375 3.5827 5.25056 3.41659 5.08333C3.26381 4.91667 3.18381 4.72222 3.17659 4.5C3.16936 4.27778 3.24936 4.08333 3.41659 3.91667L6.41659 0.916666C6.49992 0.833333 6.5902 0.774444 6.68742 0.74C6.78464 0.705555 6.88881 0.688056 6.99992 0.6875C7.11103 0.6875 7.2152 0.705 7.31242 0.74C7.40964 0.775 7.49992 0.833889 7.58325 0.916666L10.5833 3.91667C10.7499 4.08333 10.8299 4.27778 10.8233 4.5C10.8166 4.72222 10.7366 4.91667 10.5833 5.08333C10.4166 5.25 10.2185 5.33694 9.98909 5.34417C9.75964 5.35139 9.56186 5.27139 9.39575 5.10417L7.83325 3.54167V9.5C7.83325 9.73611 7.75325 9.93417 7.59325 10.0942C7.43325 10.2542 7.23548 10.3339 6.99992 10.3333C6.76381 10.3333 6.56575 10.2533 6.40575 10.0933C6.24575 9.93333 6.16603 9.73555 6.16659 9.5V3.54167Z" fill="#101010"/>
                </svg>
            </button>
          </Popover>

          {/* <PptUploader2
            courseId={courseId}
            chapterCount={order - 1}
            isImportingPpt={false}
            v2={true}
            upsell={!canUploadPpt}
            onClose={() => setIsOpen(false)}
            onlyIcon={true}
            disabled={disabled}
          /> */}

          {magicSessionCreationEnabled && <Button
            text={''}
            type="magic"
            size="small"
            disabled={disabled}
            onClick={showMagic}
            svgIcon={<MagicStarsIcon />} />}
        </>
      }
    </div>
  )
}
